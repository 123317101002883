import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "@layouts/index"
import SEO from "@components/seo"

import pageCss from "@css/components/common/page.module.styl"
import missionCss from "@css/components/recruit/mission.module.styl"
import memberCss from "@css/components/recruit/member.module.styl"
import wantedCss from "@css/components/recruit/wanted.module.styl"

import Footer from '@components/Footer'

class Privacy extends React.PureComponent {
    render(){
        const { data } = this.props
        const { bg01 } = data
        return(
            <div>
                <SEO title="プライバシーポリシー" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <h1 className={pageCss.title_mid}>
                                    プライバシーポリシー
                                </h1>
                            </div>
                            <div className={pageCss.term}>
                                <section>
                                    <h2>プライバシーポリシー</h2>
                                    <p>
                                    憧れのあの人と生電話！双方向ライブ配信アプリSUGAR（シュガー）。
                                    今までにない、双方向ライブ配信アプリ！憧れのあの人と今すぐつながろう！SUGAR（シュガー）は、誰でも知ってる憧れの人たちと直接話せる、画期的な『双方向LIVE配信アプリ』です。
                                    </p>
                                </section>
                                <section>
                                    <h2>1. 法令遵守</h2>
                                    <p>
                                    当社は、個人情報の保護に関する法律（以下「個人情報保護法」といいます）及び関連法令並びにガイドラインを遵守し、個人情報その他の利用者情報を適切に取り扱います。
                                    </p>
                                </section>
                                <section>
                                    <h2>2. 適用範囲</h2>
                                    <p>
                                        本プライバシーポリシーは、当社が提供する全てのサービスに適用されます。<br/>
                                        当社は、本プライバシーポリシーのほか、サービス別のプライバシーポリシー又は利用規約等（以下「個別規約」といいます）を定める場合があり、個別規約において個別に利用者情報の取扱いを規定する場合は、個別規約も適用されます。本プライバシーポリシーと個別規約において矛盾が発生する場合には、個別規約が優先されます。<br/>
                                        <br/>
                                        なお、「他社モジュールの設置について」等に記載される他事業者等による利用者情報の取得その他の取扱いは、本プライバシーポリシーの適用範囲ではございません。他事業者等による利用者情報の取扱いについては、他事業者等がウェブサイト等で掲示しているプライバシーポリシー等をご参照ください。<br/>
                                    </p>
                                </section>
                                <section>
                                    <h2>3. 取得する利用者情報</h2>
                                    <p>
                                        当社は、本サービスにおいて、以下の利用者情報を取得する場合があります。利用者情報をご提供いただけない場合、本サービス又は本サービスの一部をご利用いただけない場合がありますので、あらかじめご承知おきください。<br/>
                                        (1)　利用者の氏名、住所、性別、生年月日<br/>
                                        (2)　利用者の電話番号、メールアドレス<br/>
                                        (3)　利用者のLINEその他の他社アプリのID<br/>
                                        (4)　利用者が使用している携帯電話機の端末機種名、OS情報その他の機器情報<br/>
                                        (5)　利用者が使用している携帯電話機のアドレス帳に記載されている電話番号、携帯電話用メールアドレス（ただし携帯電話用メールアドレスは、利用者がフィーチャーフォンを使用している場合に限ります）<br/>
                                        (6)　利用者の位置情報、アクセスログ<br/>
                                        (7)　本サービスの利用履歴その他本サービスの利用に関する情報
                                    </p>
                                </section>
                                <section>
                                    <h2>4．顔データの収集について</h2>
                                    <p>
                                        当社は、本サービスにおけるアーカイブ配信を目的として、利用者の顔データを収集することがあります。この顔データは、収集から10年間保存されることとします。ただし、利用者からのアーカイブ削除要請、公開停止要請、または公開の停止の要望があった場合は、これに応じてデータを削除します。サービス開発に関与する外部エンジニアには、非開示契約（NDA）及びその他の関連契約を締結することにより、彼らが関与する開発期間中のみデータの閲覧を許可する場合があります。原則として顔データは保存しない方針ですが、上述の目的のため、一時的にデータを保持することがあります。
                                    </p>
                                </section>
                                <section>
                                    <h2>5. 利用者情報の利用目的</h2>
                                    <p>
                                    当社は、法令で認められる場合を除き、取得した利用者情報を、以下の目的の達成に必要な範囲においてのみ利用いたします。<br/>
                                    <br/>
                                    (1)　本サービスの提供・改善・開発のため<br/>
                                    (2)　利用者が本サービスを円滑に利用できるようにするため<br/>
                                    (3)　利用者が知り合い又は知り合いの可能性がある他の利用者を探して友だちとして登録又は推薦できるようにするため<br/>
                                    (4)　本サービスの利用に関する統計データを作成し改善につなげるため<br/>
                                    (5)　利用者からのお問い合わせに対する対応のため<br/>
                                    (6)　当社が現在提供している本サービス又は今後提供を検討しているサービスに関するアンケート実施のため<br/>
                                    (7)　今後の本サービスに関する新企画立案を行い提供するため<br/>
                                    (8)　キャンペーン等の抽選及び賞品や商品発送のため<br/>
                                    (9)　商品購入時や有料サービス利用時等におけるご請求処理のため<br/>
                                    (10)　その他本サービスに関する重要なお知らせ等、必要に応じた連絡を行うため<br/>
                                    (11)　本サービスのセキュリティ、不正利用防止のため<br/>
                                    (12)　スパム行為や不正アクセス等、不正利用防止のため<br/>
                                    (13)　不正利用が発生した場合などに本人確認や連絡を行うため<br/>
                                    (14)　パーソナライズ、広告配信のため<br/>
                                    (15)　当社又は当社以外の事業者の商品又はサービスの宣伝広告のため<br/>
                                    (16)　利用者個人にカスタマイズされた当社サービス提供のため<br/>
                                    (17)　その他、前各号に付随する目的のため
                                    </p>
                                </section>
                                <section>
                                    <h2>6. 個人データの第三者提供</h2>
                                    <p>
                                    当社は、法令で認められる場合を除き、個人データ（個人情報保護法に定義される「個人データ」をいいます）を、利用者の同意なく第三者に提供することはありません。
                                    </p>
                                </section>
                                <section>
                                    <h2>7. 個人データの取扱いの委託</h2>
                                    <p>
                                    当社は、利用目的の達成に必要な範囲において、法令に従って、お客様から取得した個人データの全部又は一部を業務委託先（外国にある委託先を含みます）に委託することがあります。この場合、当社は、業務委託先としての適格性を十分審査するとともに、委託契約の締結にあたっては守秘義務その他の必要な事項等を規定し、業務委託先における個人データの取扱状況を把握することで、個人データが適正に管理される体制作りを行います。
                                    </p>
                                </section>
                                <section>
                                    <h2>8. 他社モジュールの設置について</h2>
                                    <p>
                                    当社は、他社媒体に掲載した本サービスの広告の費用を広告代理店に支払う目的で広告掲載効果を測定するため、又は利用状況の分析等のために、他社モジュールを、本サービスのスマートフォン用アプリに設置する場合がございます。
                                    </p>
                                </section>
                                <section>
                                    <h2>9. 安全管理措置</h2>
                                    <p>
                                    当社は、利用者の個人データの漏えい、滅失又はき損等を防止するために、必要かつ適切な安全管理措置を講じます。また、個人データの安全管理が図られるよう、当社従業員に対する必要かつ適切な監督を行います。
                                    </p>
                                </section>
                                <section>
                                    <h2>10. 個人データの正確性確保・消去</h2>
                                    <p>
                                    当社は、利用目的の達成に必要な範囲内において、利用者の個人データを正確かつ最新の内容に保ちます。また、利用者が本サービスのアカウントを削除した場合、その他個人データを利用する必要がなくなったときは、当該個人データを遅滞なく消去するよう努めます。
                                    </p>
                                </section>
                                <section>
                                    <h2>11. 登録情報の確認・訂正等</h2>
                                    <p>
                                    利用者は、いつでも、本サービス上でご登録されているメールアドレスやパスワード等の登録情報を、本サービスサイト又はアプリケーション上で確認・訂正することができます。また、利用者は、登録情報の他の利用者その他第三者への公開範囲や利用設定等を変更することができます。
                                    </p>
                                </section>
                                <section>
                                    <h2>12. 保有個人データの開示、訂正等又は利用停止等</h2>
                                    <p>
                                    お客様は、当社に対して、法令に基づき、保有個人データ（個人情報保護法に定義される「保有個人データ」をいいます）の開示、利用目的の通知、訂正・追加・削除、利用停止・消去・第三者提供の停止（以下「開示等」といいます）を請求することができます。当社は、開示等の請求があった場合には、法令により開示等の義務を負わない場合を除き、当社が別途定めた手続きに従って、保有個人データの開示等を行います。<br/>
                                    <br/>
                                    開示等の請求につきましては、こちらのフォームよりご連絡下さい。<br/>
                                    なお、保有個人データの開示請求及び利用目的の通知請求については、当社が別途定めた手続きに従って、開示手数料をいただく場合がございます。
                                    </p>
                                </section>
                                <section>
                                    <h2>13. 未成年者の利用について</h2>
                                    <p>
                                    未成年の利用者が本サービスを利用し、個人情報を入力される場合には、法定代理人（親権者等）の同意のもとに行っていただけますよう、お願いいたします。
                                    </p>
                                </section>
                                <section>
                                    <h2>14. Cookie（クッキー）について</h2>
                                    <p>
                                    利用者が、クッキー等（Flashクッキー、ウェブビーコンといったトラッキング技術、その他の技術を含み、以下「クッキー等」といいます）を有効にしている場合、当社は、自動的に、クッキー等から情報を取得いたします。利用者は、ウェブブラウザ又はアプリ等の設定を変更することで、当社がクッキー等から情報を取得することを拒むことができます。ただし、その場合には、本サービスの一部機能をご利用いただけない場合がありますので、あらかじめご承知おきください。
                                    </p>
                                </section>
                                <section>
                                    <h2>15. 本プライバシーポリシーの改定</h2>
                                    <p>
                                    当社は、本プライバシーポリシーを改定することがあります。重要な変更にあたっては利用者に対してわかりやすい方法にて改定内容を告知いたします。
                                    </p>
                                </section>
                                <section>
                                    <h2>16. お問い合わせ</h2>
                                    <p>
                                    本プライバシーポリシーに関してご不明な点がある場合、本サービスにおける利用者情報の取扱いに関するご質問・苦情・ご相談等があります場合は、こちらのフォームよりご連絡ください。
                                    </p>
                                </section>
                                <section>
                                    <p>
                                    以上<br/>
                                    <br/>
                                    制定日：2019年01月01日<br/>
                                    最終改訂日：2024年05月14日
                                    </p>
                                </section>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default Privacy


export const query = graphql`
    query {
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-02@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
